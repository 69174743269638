.ticket-detail-page {
  .card {
    border: none;
    border-radius: 0px;
  }
  .card.article-title {
    background-color: $ccyellow !important;
    color: $ccblack !important;
    .super-title {
      color: $ccblack;
      font-family: $ccheadingthin;
    }
    h1 {
      color: $ccblack;
      font-size: $h2-font-size;
      @include media-breakpoint-up(sm) {
        font-size: $h15font-size;
      }
      font-weight: 500;
      small {
        color: $ccgrey;
      }
      .sub-title {
        color: $ccblack;
        font-family: $ccheadinglight;
      }
    }
  }
  .product-info-bar {
    background-color: $ccyellow !important;

    nav.navbar {
      background-color: inherit !important;
    }

    .navbar-text,
    address,
    .btn-link {
      font-family: $ccheadinglight;
      color: $black !important;
      font-size: 1.3rem;
    }
  }

  .blurry-background-image {
    background-color: $ccpink;
  }

  // .carousel-item-content {
  //   background-color: $ccpink;

  //   img.img-fluid {
  //     filter: $imagefilter;
  //     mix-blend-mode: hard-light;
  //     opacity: 1;
  //   }
  // }
}

.DayPicker-Day--disabled {
  color: $ccgrey;
}
