.navbar {
  background-color: $ccblack !important;
  color: $white;
}

body[data-scrolled] {
  .navbar {
    // background-color: rgba($color: $ccblack, $alpha: 0.75) !important;
  }
}

#header {
  //box-shadow: $box-shadow-xl;
}
#header .navbar {
  // padding: 1rem;
  font-size: $h5-font-size;
}
#header .navbar .navbar-brand {
  img {
    // height: 1rem;
    // max-height: 1rem;
    // @include media-breakpoint-up(sm) {
    height: 2rem;
    max-height: 2rem;
    transform: scale(1.5);
    // }
    @include media-breakpoint-up(md) {
      height: 4rem;
      max-height: 4rem;
    }
    &:hover,
    &:focus {
      filter: opacity(0.66);
    }
  }
}

.navbar-nav .nav-link {
  background-color: $ccblack;
  color: $ccwhite;
}

.navbar-nav {
  .dropdown-menu {
    margin: 0;
    border: 0;
    background-color: $ccblack;
    .dropdown-item {
      color: $ccwhite;
    }
  }
}

.navbar .hamburger {
  color: $ccwhite !important;
  @include media-breakpoint-down(sm) {
    padding-left: 0px;
  }
}

.navbar .hamburger.is-active .hamburger-inner,
.navbar .hamburger.is-active .hamburger-inner:after,
.navbar .hamburger.is-active .hamburger-inner:before {
  background-color: $ccyellow !important;
}

.navbar .hamburger-inner,
.navbar .hamburger-inner:after,
.navbar .hamburger-inner:before {
  height: 1px;
}

.navbar .secondary-menu,
.navbar .main-menu {
  display: flex;
  align-items: center;
  transition: all 0.2s;
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

.navbar .nav-item.last {
  .nav-link {
    @include media-breakpoint-down(sm) {
      padding-right: 0px;
    }
  }
}

.navbar .nav-link .badge.expiration {
  display: none;
  @media only screen and (min-width: 350px) {
    display: inherit;
  }
}

.offcanvas-collapse {
  .navbar-nav {
    .nav-item,
    .dropdown-item {
      margin-bottom: 0.5rem;
      background-color: rgba($color: $ccblack, $alpha: 0.9) !important;
      font-size: $h5-font-size;
      text-transform: uppercase;
      box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);
      a {
        color: $ccwhite;
      }
      &:hover {
        // text-decoration: underline;
      }
    }
  }
}

.offcanvas-collapse {
  box-shadow: none;
  background-color: transparent;
}
.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

// OC CART

.offcanvas-collapse-right {
  background: rgba($color: $gray-100, $alpha: 0.9) !important;
  backdrop-filter: blur(5px);
  box-shadow: -15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  h5 {
    font-size: $h5-font-size;
    text-transform: uppercase;
    color: $gray-900 !important;
  }
}

.shoppingcart-finisher,   // bottom border mit 'direkt zur Kasse' Button
  .sticky-bottom.is-valid {
  // aber auch der sticky beim kaufabschluss
  background: rgba($color: $ccblack, $alpha: 0.8) !important;
  backdrop-filter: blur(5px);
}
