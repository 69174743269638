#footer {
  min-height: 30vh;
  background-color: $ccblue;
  color: $white;
  a {
    color: $white;
  }
  // border-image-source: linear-gradient(to left, black 25% pink 25% 50%, yellow 50% 75%, blue 75% );
  border-image-source: repeating-linear-gradient(
    90deg,
    $ccblack,
    $ccblack 25px,
    $ccpink 25px,
    $ccpink 50px,
    $ccyellow 50px,
    $ccyellow 75px,
    $ccblue 75px,
    $ccblue 100px
  );
  border-image-slice: 1;
  border-bottom: 1rem solid;
}
