.masonry.row {
  row-gap: 1rem;
}

.sushi-container {
}

.maki {
  background: $ccyellow !important;
  color: $ccblack !important;

  @extend .p-3;
  .card-title {
    text-align: left;
    .title {
      font-family: $ccheadingfont;
      font-size: $h2-font-size;
      font-weight: 500 !important;
      line-height: 1;
    }
    .sub-title {
      font-family: $ccheadingthin;
      font-size: smaller;
      @extend .mb-2;
    }
    .price {
      font-family: $ccheadinglight;
      color: $ccblack;
    }
    .date-container {
      font-family: $ccheadinglight;
    }
  }
  .card-body {
    .location {
      font-family: $ccheadingthin;
    }
  }
}
// hover effect
a.maki.card {
  border: 2px solid transparent !important;
  border-width: 2px !important;
  border-color: transparent !important;
  border-style: solid;
  &:hover {
    box-shadow: $box-shadow-xl;
    border-color: rgba($color: $ccyellow, $alpha: 0.3) !important;
    transition: all 0.15s ease-in-out;
  }
}
