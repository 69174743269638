// Centralcomittee <- Kaffkiez <- JORIS SKIN <- FE SKIN <- BF SKIN <- JIRAFA SKIN

#root {
  padding-top: 0;
}

h1 {
  font-size: 4rem;
  font-weight: 500;
  @include media-breakpoint-up(sm) {
    font-size: $h1-font-size;
  }
}

.fal {
  font-weight: 500;
}

.btn,
.loading-spinner-content {
  text-transform: uppercase;
}

// NIGIRI

.nigiri.card {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid $gray-300;
  .nigiri-content {
    padding: 0 0 1.25rem;
    margin-top: 0.2rem;
    color: $ccgrey;
    @include media-breakpoint-down(md) {
      display: table;
    }
    @include media-breakpoint-down(sm) {
      padding: 0 0 0.6rem;
      line-height: 1.5rem !important;
    }
  }
  .nigiri-date {
    font-size: $h3-font-size;
    @include media-breakpoint-down(sm) {
      font-size: 1.2rem;
    }
  }
  .nigiri-body {
    font-size: $h2-font-size;
    border-left: 0;
    font-weight: bold;
    @include media-breakpoint-down(md) {
      padding-left: 0;
      line-height: 2rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 1.2rem;
      margin-bottom: 0.2rem;
      line-height: 1.5rem;
    }
  }
  .badge {
    z-index: 2;
    transform: rotate(2.5deg);
    font-size: 0.8em;
    @include media-breakpoint-down(sm) {
      top: 0.4rem;
    }
  }
  .title {
    font-size: 0.6em;
    margin-bottom: 0;
    color: $ccblack;
  }
  .subtitle {
    font-size: 0.5em;
    margin-bottom: 0;
    color: $gray-500;
    font-weight: normal;
    @include media-breakpoint-down(md) {
      line-height: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }
  .location {
    font-size: $h3-font-size;
    @include media-breakpoint-down(sm) {
      font-size: 1.2rem;
    }
    font-weight: 500;
    color: $ccblack;
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 0.2rem;
    }
  }
  .nigiri-cta .btn {
    color: $ccyellow;
    background: transparent;
    border: 2px solid $ccyellow;
    @include media-breakpoint-down(sm) {
      padding-top: 0.1rem;
      padding-bottom: 0.1rem;
    }
  }
}

// Product Detail Page

.detail-page {
  h1 {
    margin-top: 0;
    color: $ccblack;
    text-transform: uppercase;
    font-size: $h2-font-size;
    .sub-title {
      color: $ccgrey;
    }
  }
  .product-header-title {
    line-height: 1.4;
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $primary;
  &:hover,
  &:focus {
    color: lighten($primary, 0.2);
  }
}

.blurry-background-image {
  background-color: $ccyellow;
}

.carousel-indicators {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  position: relative;
  > a,
  > .btn {
    img {
      filter: brightness(0.5);
      margin: 0.3rem !important;
    }

    &:hover,
    &:focus,
    &.active {
      img {
        filter: brightness(1);
      }
    }
  }
}

.product-thumb {
  box-shadow: none;
  border: none;
}
.radio-list-group .product-thumb {
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
  background: $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    form {
      text-align: center;
    }
  }
}

// CHECKOUT

.checkout .sticky-bottom {
  width: 100vw;
  max-width: 100vw;
  margin-left: calc(50% - 50vw);
}

// OTHER

#product-listing,
#ticket-listing {
  padding-top: 2rem;
}
.hero-slider {
  margin-bottom: 0;
}

body.agb-detail-page h1 {
  font-size: 4rem;
  @include media-breakpoint-up(sm) {
    font-size: 4rem;
  }
}

.stage-wrapper {
  box-shadow: $box-shadow-xl;
  background-color: #fbccb0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.stage {
  box-shadow: $box-shadow-xl;
}

.boxheader {
  text-transform: uppercase;
  text-align: center;
}

// CARMENS SPECIALS
// BACKDROP

.modal-backdrop.show {
  backdrop-filter: blur(20px);
}

.offcanvas-collapse,
.offcanvas-collapse-right,
.radio-list-group,
.navbar-nav .nav-item {
  box-shadow: none !important;
}

legend {
  font-weight: $headings-font-weight;
}

// FIXES

.tt-sticky-top {
  top: 0;
}

// fix for add to cart button scrolled out of sight on iPhones.
// spinner is hidden so that add to cart button is aligned.

@include media-breakpoint-only(xs) {
  table.variant-listing {
    display: grid;
    grid-template-columns: auto;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }

    thead {
      display: none;
    }

    tbody,
    tr {
      display: inherit;
      margin-bottom: 1rem;
      background-color: $white !important;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    tr {
      grid-template-rows: auto;
      box-shadow: $box-shadow;
      //border: 1px solid $gray-200;
      th {
        padding-bottom: 0.1rem;
      }
      td:first-of-type {
        border-top: 0;
        padding-top: 0.1rem;
      }
    }

    tbody tr td,
    tbody tr th {
      text-align: left !important;
    }

    fieldset.number-input {
      button,
      input {
        width: stretch;
        width: -webkit-fill-available;
        width: fill-available;
      }
    }

    colgroup {
      display: none;
    }

    div.invisible.spinner-border {
      display: none !important;
    }

    button.add-product {
      width: 100%;
      max-width: 100%;
      span {
        display: inline !important;
      }
    }
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

@import './footer.scss';
@import './navigation.scss';
@import './maki.scss';
@import './maki2020.scss';
@import './ticketdetail.scss';
