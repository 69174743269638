.maki-container {
  .maki-2020 {
    &:hover {
      .card-body {
        box-shadow: none;
      }
      .sold-out-status {
        opacity: 1;
      }
    }

    .overlay {
      display: none;
      padding: 0;
      font-family: $ccheadinglight;

      &.sold-out-status {
        padding-left: 1.25rem;
        display: flex;
      }
      &.tag-container {
        display: flex;
        width: 100%;

        .tags {
          width: 100%;

          .tag {
            padding-left: 1.25rem;
            display: block;
            width: 100%;
          }
          .tag-6800 {
            background: $ccblue;
          }
        }
      }
    }

    .maki-img-container {
      background-color: $ccpink;
      border-left: $ccpink solid 1.5rem;
      border-right: $ccpink solid 1.5rem;
      border-top: $ccpink solid 1.5rem;

      padding-bottom: 110px;

      // img {
      //   filter: $imagefilter;
      //   mix-blend-mode: hard-light;
      //   opacity: 1;
      // }
    }

    .card-img-overlay {
      // background: rgba($ccyellow, 0.6);
      // backdrop-filter: blur(10px);
      background: $ccyellow;
      color: $ccblack;

      .card-body {
        background: none;
        backdrop-filter: none;
        padding-top: 0rem;
        padding-bottom: 0.3rem;

        .card-title {
          margin-bottom: 0;
          line-height: 1.1;

          .title {
            font-size: 1.7rem;
          }
          .sub-title {
            font-family: $ccheadinglight;
          }
          .start_on {
            font-family: $ccheadinglight;
            font-size: 1.3rem;
          }
          .location {
            font-family: $ccheadinglight;
            font-size: 1.3rem;
          }
          .prices {
            display: inline;
            font-family: $ccheadinglight;
            font-size: 1.3rem;
          }
        }
        .active-menu {
          color: rgba($ccblack, 0.7);
          // text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

          .small {
          }

          .btn {
            margin-bottom: 0.5rem !important;
          }
        }
      }
    }
  }
}
